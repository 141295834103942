.profile {
  display: flex;
  width: 500px;
  max-height: 300px;
  border: 1px black solid;
  border-radius: 5px;
  margin: 20px;
}

.profile img {
  max-width: 200px;
  margin-right: 10px;
}

h1 {
  font-size: 5em;
}
