.Slytherins {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px;
  padding: 128px;
}

.Slytherins__profile {
  display: block;
}

.Slytherins__profile > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 3px;
  margin-bottom: 16px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.Slytherins__profile > h2 {
  font-size: 32px;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-family: 'Merriweather';
}

.Slytherins__profile > ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2;
}

.Slytherins__profile > ul > li {
  padding-right: 16px;
}

@media (max-width: 768px) {
  .Slytherins {
    grid-template-columns: repeat(1, 1fr);
  }
}

.profile {
  display: -webkit-flex;
  display: flex;
  width: 500px;
  max-height: 300px;
  border: 1px black solid;
  border-radius: 5px;
  margin: 20px;
}

.profile img {
  max-width: 200px;
  margin-right: 10px;
}

h1 {
  font-size: 5em;
}

:root {
  --color-black: #0A0B0F;
  --color-green: #036452;
  --color-grey: #90A3A2;
  --color-gold: #EEC749;
  --item-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: Roboto;
  color: white;
  background: #036452;
  background: var(--color-green);
}

a,
a:visited {
  color: inherit;

}

