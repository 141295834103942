.Slytherins {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px;
  padding: 128px;
}

.Slytherins__profile {
  display: block;
}

.Slytherins__profile > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 3px;
  margin-bottom: 16px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.Slytherins__profile > h2 {
  font-size: 32px;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-family: 'Merriweather';
}

.Slytherins__profile > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2;
}

.Slytherins__profile > ul > li {
  padding-right: 16px;
}

@media (max-width: 768px) {
  .Slytherins {
    grid-template-columns: repeat(1, 1fr);
  }
}
