:root {
  --color-black: #0A0B0F;
  --color-green: #036452;
  --color-grey: #90A3A2;
  --color-gold: #EEC749;
  --item-shadow: 0 2px 5px 0px rgba(0,0,0,0.2);
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: Roboto;
  color: white;
  background: var(--color-green);
}

a,
a:visited {
  color: inherit;

}
